import { Component, OnInit } from '@angular/core';
import { CoolLocalStorage } from '@angular-cool/storage';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { Company } from '../../../models/company';

@Component({
  selector: 'app-paypal-connect',
  templateUrl: './paypal-connect.component.html',
  styleUrls: ['./paypal-connect.component.scss']
})
export class PaypalConnectComponent implements OnInit {

  constructor(
    private _vault: CoolLocalStorage,
    private _route: ActivatedRoute,
    private _companyService: CompanyService
  ) {}

  ngOnInit(): void {
    const company: Company = this._vault.getObject(`${environment.vaultPrefix}.paypalConnect`);

    this._route.queryParamMap.subscribe(params => {
      const merchantIdInPayPal = params.get('merchantIdInPayPal');
      const consent = params.get('permissionsGranted');
      const companyId = params.get('companyId');

      console.log('[PayPal] Redirect params:', { merchantIdInPayPal, consent });

      if (!merchantIdInPayPal || consent !== 'true') {
        console.warn('[PayPal] Missing merchantId or user did not grant permissions.');
        return;
      }

      this._companyService.connectPayPalAccount({
        companyId,
        merchantIdInPayPal
      }).subscribe(() => {
        window.location.href = `${environment.portalUrl}/groups/${companyId}/general`;
      }, error => {
        console.error(error);
      });
    });
  }
}
