import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import md5 from 'crypto-js/md5';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {InvoiceService} from '../../../../../../services/pas/invoice.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss'],
  providers: [InvoiceService]
})
export class EditInvoiceComponent implements OnInit {
  invoice: any;
  invoiceForm: FormGroup;
  vatOptions = [9, 21];

  constructor(
    private _invoiceService: InvoiceService,
    private _dialogService: TdDialogService,
    private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<EditInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.invoice = data.invoice;
  }

  ngOnInit(): void {
    this.invoiceForm = this.fb.group({
      lines: this.fb.array([])
    });
  }

  get invoiceLines(): FormArray {
    return this.invoiceForm.get('lines') as FormArray;
  }

  addInvoiceLine(): void {
    const newLine = this.fb.group({
      id: [this.generateMd5Id()],
      description: ['', Validators.required],
      vatPerc: [21, Validators.required],
      total: [0, [Validators.required, Validators.min(0)]],
      totalExVat: [{ value: 0, disabled: true }],
      type: ['manual']
    });

    this.invoiceLines.push(newLine);
    this.recalculateTotals();
  }

  deleteInvoiceLine(index: number): void {
    this.invoiceLines.removeAt(index);
    this.recalculateTotals();
  }

  deleteExistingInvoiceLine(index: number): void {
    this.invoice.lines.splice(index, 1);
    this.recalculateTotals();
  }

  recalculateTotals(): void {
    let subtotal = 0;
    let vatTotal = 0;

    [...this.invoice.lines, ...this.invoiceLines.controls.map(line => line.value)].forEach(line => {
      if (line.total && line.total.total && line.total.total !== 0) {
        const vatPerc = line.total.taxPerc;
        // tslint:disable-next-line:radix
        const total = parseInt(line.total.total); // Convert from Euros to cents
        const totalExVat = total / (1 + vatPerc / 100);
        subtotal += totalExVat;
        vatTotal += total - totalExVat;
      } else if (line.total && line.total !== 0) {
        const vatPerc = line.vatPerc;
        const total = line.total * 100; // Convert from Euros to cents
        const totalExVat = total / (1 + vatPerc / 100);

        subtotal += totalExVat;
        vatTotal += total - totalExVat;
      }
    });

    this.invoice.total.total = subtotal + vatTotal;
    this.invoice.total.tax = vatTotal;
  }

  generateMd5Id(): string {
    return md5(Date.now().toString() + Math.random().toString());
  }

  valOrZero(input): void {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
  }

  inputFocused(event): void {
    event.target.select();
  }

  save(): void {
    this.data.parent._loadingService.register();
    // Convert new lines' total to cents before saving
    const newId = this.generateMd5Id();
    const newLines = this.invoiceLines.value.map(line => ({
      ...line,
      id: newId,
      total: {
        total: Math.round(line.total * 100), // Convert to cents
        tax: Math.round(((line.total * 100) * line.vatPerc) / (100 + line.vatPerc)),
        taxPerc: line.vatPerc,
        currency: this.invoice.total.currency || 'EUR'
      }
    }));

    this.invoice.lines = [...this.invoice.lines, ...newLines]; // Append new lines to existing ones

    this._invoiceService.update(this.invoice.id, this.invoice).subscribe(() => {
      this.dialogRef.close(this.invoice);
      this.data.parent._loadingService.resolve();
      this.data.parent.reloadAllData();
      this._snackbar.open(ucFirst(this.data.parent._translateService.instant('invoice_updated_message')), this.data.parent._translateService.instant('ok'), {
        duration: 3000,
        panelClass: 'snack-success'
      });
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
