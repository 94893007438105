import {Component, Input, OnInit} from '@angular/core';
import {Ride} from '../../../../../models/ride';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ride-details-data',
  templateUrl: './ride-details-data.component.html',
  styleUrls: ['./ride-details-data.component.scss']
})
export class RideDetailsDataComponent implements OnInit {

  @Input() ride: Ride = new Ride();
  confirmed = true;
  expanded = false;

  constructor(
    protected _translationService: TranslateService,
    private _route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    const jobOffer = this.ride.LastJobOffer;
    this.confirmed = (!(jobOffer && jobOffer.preAssigned && jobOffer.response.toLowerCase() === 'none'));
  }
}
