import {GeoPoint} from './geo-point';

export enum LocationType {
  area = 'area',
  point = 'point',
  predefined = 'predefined',
}

export type Pair = [number, number];

export class Location {
  static descriptors = {
    point: [
      'address',
      'airport',
      'hospital',
      'hotel',
      'railway_station',
      'other',
    ],
    area: [
      'city',
      'country',
      'neighborhood',
      'province',
      'state',
      'other',
    ]
  }
  _id?: string;
  name: string;
  point: GeoPoint;
  area: any;
  type: LocationType;
  serviceArea = false;
  descriptor?: string;
  address?: string;
  isShared: boolean;
  radius = 100;
  locationPolicyId?: string;
  locationPolicy?: any = {};

  constructor() {
  }

  static frontend = (type: 'point' | 'area' | 'predefined') =>
    ({
      area: 'area',
      point: 'location',
      predefined: 'predefined locations and area',
    })[type] || 'No such name for location';

  static getIcon = (label: string): string => ({
    address: 'markunread_mailbox',
    airport: 'local_airport',
    hospital: 'local_hospital',
    hotel: 'local_hotel',
    railway_station: 'train',
    city: 'location_city',
    country: 'language',
    neighborhood: 'local_florist',
    province: 'flag',
    state: 'account_balance',
    other: 'place',
  }[label] || 'not_listed_location');
}
