import {environment} from '../../environments/environment';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {CoolLocalStorage, CoolStorageModule} from '@angular-cool/storage';
import {AuthGuard} from '../guards/auth.guard';
import 'hammerjs';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MainComponent} from './layout/main/main.component';
import {LoginComponent} from './portal/login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotFoundComponent} from './portal/not-found/not-found.component';
import {ResetPasswordComponent} from './portal/reset-password/reset-password.component';
import {UserProfileComponent} from './portal/user-profile/user-profile.component';
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {SplitLayoutComponent} from './layout/split-layout/split-layout.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ErrorInterceptor} from '../http-interceptors/error.interceptor';
import {ErrorService} from '../services/error.service';
import {CovalentDialogsModule} from '@covalent/core/dialogs';
import {CovalentLayoutModule} from '@covalent/core/layout';
import {CovalentLoadingModule} from '@covalent/core/loading';
import {CovalentMediaModule} from '@covalent/core/media';
import {CovalentMessageModule} from '@covalent/core/message';
import {MomentModule} from 'angular2-moment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {UcFirstPipeModule} from '../pipes/uc-first.pipe';
import {ReplacePipeModule} from '../pipes/replace.pipe';
import {NavigationService} from '../services/navigation.service';
import {CompanyService} from '../services/company.service';
import {DriverModule} from './personal/driver.module';
import {CompanyModule} from './company/company.module';
import {WidgetsModule} from '../widgets/widgets.module';
import {UtilityService} from '../services/utility.service';
import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {Globals} from 'globals';
import {MainMenuComponent} from './layout/main-menu/main-menu.component';
import {ZendeskWidgetComponent} from '../zendesk-widget/zendesk-widget.component';
import {DynamicFormModule} from 'app/components/global/dynamic-form/dynamic-form.module';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeUs from '@angular/common/locales/en';
import {MatTimepickerModule} from 'mat-timepicker';
import {MatSelectModule} from '@angular/material/select';
import {OperatorModule} from './operator/operator.module';
import {CompanyCreateComponent} from './portal/company-create/company-create.component';
import {WorkspaceSelectionComponent} from './portal/workspace-selection/workspace-selection.component';
import {BusinessLicenseComponent} from './global/business-license/business-license.component';
import {SignupComponent} from './portal/signup/signup.component';
import {VerifyEmailComponent} from './portal/verify-email/verify-email.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {SignupSuccessfulComponent} from './portal/signup-successful/signup-successful.component';
import {DispatchgroupModule} from './dispatchgroup/dispatchgroup.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PersonalLicenseComponent} from './global/personal-license/personal-license.component';
import {UpsertComponent} from './global/upsert/upsert.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {NgxLoadingButtonsModule} from 'ngx-loading-buttons';
import {StripeConnectComponent} from './portal/stripe-connect/stripe-connect.component';
import {ConnectEmailComponent} from './portal/connect-email/connect-email.component';
import {MergeEmailComponent} from './portal/merge-email/merge-email.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DaAppInstallService} from '../services/da-app-install.service';
import {ErrorDialogComponent} from './portal/error-dialog/error-dialog.component';
import {FieldErrorComponent} from './portal/field-error/field-error.component';
import {SupportWorkspaceSelectionComponent} from './portal/support-workspace-selection/support-workspace-selection.component';
import {KeepHtmlPipeModule} from '../pipes/keep-html.pipe';
import {TranslationService} from '../services/translate.service';
import {MatTabsModule} from '@angular/material/tabs';
import {CovalentSearchModule} from '@covalent/core/search';
import {A11yModule} from '@angular/cdk/a11y';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { PaypalConnectComponent } from './portal/paypal-connect/paypal-connect.component';

export function createTranslateLoader(http: HttpClient, vault: CoolLocalStorage) {
  const projectUrl = 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968/';
  const result = vault.getObject(projectUrl);
  console.log(result);
  if (result) {
    return result;
  } else {
    const loader = new TranslateHttpLoader(http, projectUrl, '');
    vault.setObject(projectUrl, loader)
    return loader;
  }
}

registerLocaleData(localeNl);
registerLocaleData(localeUs);

const CUSTOM_PARSE_DATE_INPUT = 'DD-MM-YYYY, HH:mm';
const CUSTOM_DISPLAY_DATE_INPUT = 'DD-MM-YYYY, HH:mm';

// If using Moment
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: CUSTOM_PARSE_DATE_INPUT
  },
  display: {
    dateInput: CUSTOM_DISPLAY_DATE_INPUT,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    ZendeskWidgetComponent,
    MainComponent,
    SplitLayoutComponent,
    LoginComponent,
    DashboardComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    UserProfileComponent,
    CompanyCreateComponent,
    MainMenuComponent,
    WorkspaceSelectionComponent,
    BusinessLicenseComponent,
    SignupComponent,
    VerifyEmailComponent,
    SignupSuccessfulComponent,
    PersonalLicenseComponent,
    UpsertComponent,
    StripeConnectComponent,
    ConnectEmailComponent,
    MergeEmailComponent,
    ErrorDialogComponent,
    FieldErrorComponent,
    SupportWorkspaceSelectionComponent,
    PaypalConnectComponent
  ],
  imports: [
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    NgxLoadingButtonsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    AgmCoreModule.forRoot(),
    MomentModule,
    FormsModule,
    UcFirstPipeModule,
    ReplacePipeModule,
    ReactiveFormsModule,
    CovalentLayoutModule,
    CovalentMessageModule,
    CovalentLayoutModule,
    CovalentLoadingModule,
    CovalentMediaModule,
    CovalentDialogsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSidenavModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    CoolStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        // useFactory: createTranslateLoader,
        deps: [HttpClient, CoolLocalStorage]
      }
    }),
    WidgetsModule,
    DynamicFormModule,
    (environment.modules.CompanyModule ? CompanyModule : []),
    (environment.modules.CompanyModule ? OperatorModule : []),
    (environment.modules.CompanyModule ? DispatchgroupModule : []),
    (environment.modules.DriverModule ? DriverModule : []),
    AppRoutingModule,
    MatTimepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    BsDropdownModule.forRoot(),
    KeepHtmlPipeModule,
    MatTabsModule,
    CovalentSearchModule,
    A11yModule
  ],
  providers: [
    Globals,
    AuthGuard,
    AuthService,
    UserService,
    ErrorService,
    Title,
    NavigationService,
    DaAppInstallService,
    UtilityService,
    CompanyService,
    GoogleMapsAPIWrapper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: []},
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private _translate: TranslateService;

  constructor(
    _translate: TranslateService
  ) {
    this._translate = _translate;
    /**
     * The lang to use, if the lang isn't available, it will use the current loader to get them
     */
    _translate.use('en');
  }
}
