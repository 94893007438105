<app-no-contract *ngIf="this.company && (this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)) ||
['webbooker_license','webbooker_plus_license'].includes(this.company.ActiveContract.type))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="pricing">
    <mat-tab-group>
      <mat-tab label="{{'open_driver_invoices'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>move_to_inbox</mat-icon>
          {{'open_driver_invoices'|translate}}
        </ng-template>
        <ng-template tdLoading="data.open.loaded">
          <app-open-invoices (count)="openInvoiceCountChanged($event)"
                             *ngIf="dataLoaded"
                             [companyId]="companyId"
                             [context]="'company'"
                             [invoiceView]="'settlements'"
                             [searchIds]="searchIds"
                             [type]="'draft'">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{'driver_invoice_pending'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>history</mat-icon>
          {{'driver_invoice_pending'|translate}}
        </ng-template>
        <ng-template tdLoading="data.pending.loaded">
          <app-open-invoices *ngIf="dataLoaded"
                             [companyId]="companyId"
                             [context]="'company'"
                             [invoiceView]="'settlements'"
                             [searchIds]="searchIds"
                             [type]="'pending'">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{'driver_invoice_history'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>history</mat-icon>
          {{'driver_invoice_history'|translate}}
        </ng-template>
        <ng-template tdLoading="data.history.loaded">
          <app-open-invoices *ngIf="dataLoaded"
                             [companyId]="companyId"
                             [context]="'company'"
                             [invoiceView]="'settlements'"
                             [searchIds]="searchIds"
                             [type]="'final'">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{'invoice_export_status'|translate}}" *ngIf="this.company && this.company.settings && this.company.settings.exportInvoices">
        <ng-template mat-tab-label>
          <mat-icon>import_export</mat-icon>
          {{'invoice_export_status'|translate}} <span *ngIf="exportCount" class="tab-total">({{exportCount}})</span>
        </ng-template>
        <ng-template tdLoading="data.history.export">
          <app-open-invoices *ngIf="dataLoaded"
                             [companyId]="companyId"
                             [context]="'company'"
                             [invoiceView]="'settlements'"
                             [searchIds]="searchIds"
                             (exportCount)="exportCountChanged($event)"
                             [type]="'export'">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
