import {AfterContentInit, AfterViewInit, Component, Directive, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {Discount} from 'app/models/discount';
import {Location} from 'app/models/location';
import {Rule} from '../../../../../../models/rule';
import {LocationService} from '../../../../../../services/tps/location.service';
import 'rxjs/add/operator/debounceTime';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {CustomLazyAPIKeyLoader} from '../../../../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../../../../googleMapConfig';
import {NavigationService} from '../../../../../../services/navigation.service';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {DOWN_ARROW, TAB, UP_ARROW} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {Company} from '../../../../../../models/company';

interface AutoCompleteInput {
  type: string,
  name: string,
  tooltip: string,
  matTooltipPosition: 'above' | 'left' | 'right' | 'below',
  matTooltipShowDelay: number,
  placeholder: string,
  searchResults: Location[],
  location: AbstractControl,
  autoComplete: UntypedFormControl,
}

@Directive({standalone: true, selector: '[tab-selected]'})
export class TabSelected implements AfterViewInit {
  constructor(private auto: MatAutocomplete) {}
  ngAfterViewInit() {
    this.auto._keyManager.onKeydown = (event: KeyboardEvent) => {
      switch (event.keyCode) {
        case TAB:
          if (this.auto.isOpen) {
            const option = this.auto.options.find(x => x.active);
            if (option) {
              option.select();
              event.preventDefault();
              return;
            }
          }
          this.auto._keyManager.tabOut.next();
          break;
        case DOWN_ARROW:
          this.auto._keyManager.setNextItemActive();
          break;

        case UP_ARROW:
          this.auto._keyManager.setPreviousItemActive();
          break;
      }
    };
  }
}

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [LocationService,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}]
})
export class LocationPickerComponent implements OnInit, AfterContentInit {
  static cachedResults: Location[];
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild('locationInput_departure', {read: MatAutocompleteTrigger}) autoTriggerDeparture: MatAutocompleteTrigger;
  @ViewChild('locationInput_destination', {read: MatAutocompleteTrigger}) autoTriggerDestination: MatAutocompleteTrigger;
  @Input() entity: Rule | Discount;
  @Input() form: UntypedFormGroup;
  @Input() allowEmpty: boolean;
  @Input() floatingLabel: 'never' | 'always' | 'auto' = 'auto';
  @Input() type: 'fixed' | 'dynamic'
  @Input() ruleType: 'price' | 'discount'

  query: string | Object;
  companyId: string;
  @Input() locations = [];
  autocompleteInputs: AutoCompleteInput[] = [
    {
      type: 'departure',
      name: 'departureId',
      tooltip: 'pick_up_within',
      matTooltipPosition: 'above',
      matTooltipShowDelay: 500,
      placeholder: 'pickup_address_in_area',
      searchResults: [],
      location: new UntypedFormControl(),
      autoComplete: new UntypedFormControl(),
    },
    {
      type: 'destination',
      name: 'destinationId',
      tooltip: 'drop_off_within',
      matTooltipPosition: 'above',
      matTooltipShowDelay: 500,
      placeholder: 'dropoff_address_in_area',
      searchResults: [],
      location: new UntypedFormControl(),
      autoComplete: new UntypedFormControl(),
    }
  ]
  company: Company;
  /**
   * Get icon.
   */
  getIcon = Location.getIcon;

  protected readonly ucFirst = ucFirst;

  constructor(
    private _locationService: LocationService,
    private _dialogService: TdDialogService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    protected _translationService: TranslateService
  ) {
    const {company} = this._route.parent.snapshot.data;
    this.company = company;
  }

  chooseFirstOption(): void {
    if (this.matAutocomplete.options && this.matAutocomplete.options.first) {
      this.matAutocomplete.options.first.select();
    }
  }

  /**
   * When enter is pressed, see if the user may want to create a new
   * location.
   */
  keyup(input: AutoCompleteInput, event: any) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.chooseFirstOption();
    }


    // const noDialogRequired = (event.keyCode !== 13 && event.keyCode !== 9)
    //   // Results are already found
    //   || input.searchResults.length
    //   // Location already set
    //   || input.location.value
    //   // Query is not a string
    //   || typeof this.query !== 'string'
    //   // Query has no value
    //   || !this.query.length
    //
    // if (noDialogRequired) {
    //   return;
    // }
    //
    // this._dialogService.openConfirm({
    //   message: 'This location doesn\'t exist. Do you wish to create it?',
    //   title: 'New Location',
    //   acceptButton: 'Ok',
    //   cancelButton: 'Cancel',
    //   disableClose: true,
    // }).afterClosed().subscribe(confirm => {
    //   if (!confirm) {
    //     return;
    //   }
    //   this._router.navigate([
    //     `/groups/${this.companyId}/locations/point/add`
    //   ]);
    // });
  }

  /**
   * On component initialize.
   */
  ngOnInit() {
    this._route.parent.params.subscribe(params =>
      this.companyId = params['id']);

    // The locations from the rule entity are set,
    // some of those props don't exist on the form
    this.autocompleteInputs.forEach(c => {
      c.autoComplete.valueChanges
        .debounceTime(150)
        .subscribe(q => this.search(c, q))
      if (this.entity) {
        c.autoComplete.setValue(this.entity[c.type]);
      }
      c.location = this.form.controls[c.name];
    });
    // this.loadData()
  }

  /**
   * After view init.
   */
  ngAfterContentInit() {
    this.autocompleteInputs.forEach(c => {
      if (!this.allowEmpty) {
        return;
      }

      if (!c.location || !c.location.value && this.company && this.company.settings && this.company.settings.serviceArea) {
        c.autoComplete.setValue({
          _id: this.company.settings.serviceAreaId,
          descriptor: '',
          name: ucFirst(this._translationService.instant('servicearea')),
          type: 'area'
        });
      } else if (!c.location || !c.location.value) {
        c.autoComplete.setValue({
          _id: null,
          descriptor: '',
          name: ucFirst(this._translationService.instant('everywhere')),
          type: 'point'
        });
      }
    })
  }

  // ngAfterViewInit() {
  //   console.log(this.autoTriggerDeparture);
  //   console.log(this.autoTriggerDestination);
  //   if (this.autoTriggerDeparture) {
  //     this.autoTriggerDeparture.panelClosingActions.subscribe(x => {
  //       if (this.autoTriggerDeparture.activeOption) {
  //         console.log(this.autoTriggerDeparture.activeOption.value)
  //         this.form.controls['departureId'].setValue(this.autoTriggerDeparture.activeOption.value)
  //       }
  //     });
  //   }
  //   if (this.autoTriggerDestination) {
  //     this.autoTriggerDestination.panelClosingActions.subscribe(x => {
  //       if (this.autoTriggerDestination.activeOption) {
  //         console.log(this.autoTriggerDestination.activeOption.value)
  //         this.form.controls['destinationId'].setValue(this.autoTriggerDestination.activeOption.value)
  //       }
  //     })
  //   }
  // }

  /**
   * Load some latest locations that can be chosen.
   */
  loadData = async () => {
    this.autocompleteInputs
      .forEach(c => c.searchResults = this.locations);
  }

  /**
   * Display autocomplete result location.
   */
  displayResult = (location: Location) => location ? location.name : null;

  /**
   * Search.
   */
  search(input: AutoCompleteInput, query: string | Object) {
    this.query = query;

    if (typeof query !== 'string') {
      return;
    }

    // If a query is emptied, clear the input element and searches
    if (query.length < 2) {
      this.setLocation(input, {_id: null});
      input.searchResults = [];
      // Find locations using the query string
      this._locationService.getAll({
        where: {
          and: [
            {
              companyId: this.companyId
            }
          ]
        },
        limit: 15,
      }).subscribe(locations => input.searchResults = locations);
      return;
    }

    // Find locations using the query string
    this._locationService.getAll({
      where: {
        and: [
          {
            companyId: this.companyId
          },
          {
            or: [
              {address: {regexp: query + '/i'}},
              {name: {regexp: query + '/i'}},
            ]
          }
        ]
      },
      limit: 15,
    }).subscribe(locations => input.searchResults = locations);
  }

  /**
   * Sets location for discount.
   */
  setLocation(autocompleteInput: AutoCompleteInput, value) {
    autocompleteInput.searchResults = [];
    autocompleteInput.location.setValue(value._id);
    autocompleteInput.location.markAsTouched();
    autocompleteInput.location.markAsDirty();
    this.entity[autocompleteInput.name] = value._id;
    this.entity[autocompleteInput.type] = value;

    if (this.form.controls.type.value === 'hourly' && this.ruleType !== 'discount') {
      this.form.controls.ruleLocation.setValue('');
    } else if (this.form.controls.type.value === 'fixed') {
      this.form.controls.ruleLocation.setValue('noLimit');
    }
  }

  focusOption(input, event) {
    if (typeof this.query !== 'string') {
      // @ts-ignore
      if (this.query && this.query.name === 'Everywhere') {
        this.search(input, '');
        // @ts-ignore
      } else if (this.query && this.query.name === ucFirst(this._translationService.instant('servicearea'))) {
        this.search(input, '');
      }
    }
  }

  getAutocompleteInputs() {
    if (!this.form || !this.form.controls || !this.form.controls.ruleLocation) {
      return this.autocompleteInputs;
    }
    if (this.form.controls.ruleLocation.value === 'noLimit') {
      return this.autocompleteInputs;
    } else if (this.form.controls.ruleLocation.value === 'either') {
      return [this.autocompleteInputs[0]];
    } else {
      return this.autocompleteInputs;
    }
  }
}
