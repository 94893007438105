import {GoogleMapsScriptProtocol, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral, MapsAPILoader} from '@agm/core';
import {Inject, Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';

@Injectable()
export class CustomLazyAPIKeyLoader extends MapsAPILoader {
  private currentKey: string;
  private _scriptLoadingPromise: any;
  private _config: LazyMapsAPILoaderConfigLiteral;
  private _windowRef: any = window;
  private _documentRef: any = window.document;

  constructor(@Inject(LAZY_MAPS_API_CONFIG) config: any,
              private _vault: CoolLocalStorage,
              private route: ActivatedRoute) {
    super();

    try {
      this.currentKey = this._vault.getObject(`${environment.vaultPrefix}.currentGoogleMapKey`);
    } catch (e) {
      this._vault.setObject(`${environment.vaultPrefix}.currentGoogleMapKey`, null);
    }
    this._config = config || {};
  }

  load() {
    if (this._scriptLoadingPromise) {
      return this._scriptLoadingPromise;
    }
    const script = this._documentRef.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    const callbackName = `angular2GoogleMapsLazyMapsAPILoader`;

    // You can hit an http request to get key bcd y http.get method.
    // Here I have already loaded that key before and saved in global service file and used here...
    let key = environment.googleApiKey;
    // console.log('this.route.parent', this.route.parent);

    if (this.route.parent) {
      const {company} = this.route.parent.snapshot.data;
      if (company && company.googleKey && company.googleKey.active) {
        key = company.googleKey.clientSecret;
      }
    }

    // console.log('map currentKey', this.currentKey);
    // console.log('map key', key);
    if (key && (this.currentKey !== key || typeof (google) === 'undefined') || typeof (google.maps) === 'undefined') {
      this._vault.setObject(`${environment.vaultPrefix}.currentGoogleMapKey`, key);
      this._config.apiKey = key;
      script.src = this._getScriptSrc(callbackName);
      this._documentRef.body.appendChild(script);

      this._scriptLoadingPromise = new Promise((resolve: Function, reject: Function) => {
        (this._windowRef)[callbackName] = () => {
          resolve();
        };

        script.onerror = (error: Event) => {
          reject(error);
        };
      });
    } else {
      this._scriptLoadingPromise = new Promise((resolve: Function) => {
        resolve();
      });
    }

    return this._scriptLoadingPromise;
  }

  private _getScriptSrc(callbackName: string): string {
    const protocolType: GoogleMapsScriptProtocol =
      (this._config && this._config.protocol) || GoogleMapsScriptProtocol.HTTPS;
    let protocol: string;

    switch (protocolType) {
      case GoogleMapsScriptProtocol.AUTO:
        protocol = '';
        break;
      case GoogleMapsScriptProtocol.HTTP:
        protocol = 'http:';
        break;
      case GoogleMapsScriptProtocol.HTTPS:
        protocol = 'https:';
        break;
    }

    const hostAndPath: string = this._config.hostAndPath || 'maps.googleapis.com/maps/api/js';
    const queryParams = {
      v: (this._config.apiVersion || '3'),
      callback: callbackName,
      key: this._config.apiKey,
      client: this._config.clientId,
      channel: this._config.channel,
      libraries: this._config.libraries,
      region: this._config.region,
      language: this._config.language
    };
    const params: string =
      Object.keys(queryParams)
        .filter((k: string) => queryParams[k] != null)
        .filter((k: string) => {
          // remove empty arrays
          return !Array.isArray(queryParams[k]) ||
            (Array.isArray(queryParams[k]) && queryParams[k].length > 0);
        })
        .map((k: string) => {
          // join arrays as comma seperated strings
          const i = queryParams[k];
          if (Array.isArray(i)) {
            return {key: k, value: i.join(',')};
          }
          return {key: k, value: queryParams[k]};
        })
        .map((entry: { key: string, value: string }) => {
          return `${entry.key}=${entry.value}`;
        })
        .join('&');
    return `${protocol}//${hostAndPath}?${params}`;
  }
}
