import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../../services/navigation.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Company} from 'app/models/company';
import {Driver} from '../../../models/driver';
import {environment} from '../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {BusinessLicenseComponent} from '../business-license/business-license.component';
import {UtilityService} from '../../../services/utility.service';

interface Collection {
  [key: string]: any;
}

interface ModelService {
  getAll(filter: {}, context: string);
}

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent extends BusinessLicenseComponent implements OnInit, OnDestroy {
  originalData: Collection;
  filteredData: Collection;
  modelService: ModelService;
  translations: string[];
  sortBy;
  filteredTotal: any[] = [];
  collectionData: any[] = [];
  searchTerm = '';
  modelName: string;
  tabIndex = 0;
  selectedTabIndex = 0;
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  sortOrderHistory: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  sortByHistory = 'requestedDate';
  companyId: string;
  context: string;
  currency: string;
  company: Company;
  driver: Driver;
  filterData: any;
  actionName;
  error: any;
  groupData: any;
  filterDates: any = {
    'from': null,
    'to': null
  };
  types = ['all'];
  paging = {};
  selectFilter = {};
  columns = [];
  pageSize = 20;
  currentPage = 1;
  fromRow = 1;
  isDialog = false;
  filteredTotals: any;
  updatePrios = false;

  protected link = true;
  protected defaultPriority = false;
  protected _route: ActivatedRoute;
  protected _router: Router;
  protected _vault: CoolLocalStorage;
  protected _loadingService: TdLoadingService;
  protected _dataTableService: TdDataTableService;
  protected _navigationService: NavigationService;

  constructor(
    protected injector: Injector,
  ) {
    super();
    this._route = injector.get(ActivatedRoute);
    this._router = injector.get(Router);
    this._vault = injector.get(CoolLocalStorage);
    this._loadingService = injector.get(TdLoadingService);
    this._dataTableService = injector.get(TdDataTableService);
    this._navigationService = injector.get(NavigationService);

    if (this._route.parent) {
      this._route.parent.params.subscribe(params => {
        this.companyId = params['id'];
      });

      this.context = this._route.routeConfig['context'] || 'driver';
    }

    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.currency = company.currency;
      this.company = company;
    } else {
      const driver: Driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
      this.driver = driver;
    }
    // The columns of the table which will be filtered based on
    // a type property ('all' column will contain all types)
    if (!this.originalData) {
      this.originalData = {
        all: [],
      }
    }

    this.filteredData = Object.assign({}, this.originalData);
    this.filteredData = {
      all: 0,
    }
    if (!this.actionName) {
      this.actionName = `${this.modelName}`
    }
  }

  _ngOnInit() {
  }

  ngOnInit() {
    this._ngOnInit();

    if (!this.isDialog) {
      this.setTitle(this.modelName);
      this.setLink(this.modelName);
    }

    this.types = Object.keys(this.originalData);
    this.types.forEach(key => {
      if (!this.paging[key]) {
        this.paging[key] = {
          total: 0,
          fromRow: 1,
          currentPage: 1,
          pageSize: this.pageSize,
        }
      }
    });
    this.types = Object.keys(this.paging);
    this.loadData(this.selectFilter);
  }

  setLink(name: string) {
    console.log(`/groups/${this.companyId}/${name}/add`);
    if (!this.link) {
      return;
    }
    this._navigationService.setActionLink(
      `/groups/${this.companyId}/${name}/add`
    );
  }

  setAddRoute(context) {
    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      if (this.context !== 'driver') {
        this._navigationService.setActionLink(`/groups/${this.companyId}/${context}/add`);
      }
    });
  }

  setTitle(name: string) {
    this._navigationService.setBodyTitle(name);
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
  }

  filter(type: string) {
    // console.log(this.originalData[type]);
    if (this.searchTerm) {
      this.filteredData[type] = this._dataTableService.filterData(
        this.originalData[type],
        this.searchTerm,
        true);
    } else {
      this.filteredData[type] = this.originalData[type];
    }

    this.filteredData[type] = this._dataTableService.sortData(
      this.filteredData[type],
      this.sortBy,
      this.sortOrder);

    this.filteredData[type] = this._dataTableService.pageData(
      this.filteredData[type],
      this.paging[type].fromRow,
      this.paging[type].currentPage * this.paging[type].pageSize);

    if (this.filteredData[type]) {
      this.filteredTotal[type] = this.filteredData[type].length;
    }
    this.afterFilter();
  }

  exportFormat(record: any) {
    return record;
  }

  collectionFormat(record: any) {
    return record;
  }

  afterFilter() {

  }

  onTabChange(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    this.filterAllTabs();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent) {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filterAllTabs();
  }

  search(searchTerm: string) {
    if (searchTerm === this.searchTerm) {
      return;
    }
    this.startLoader();
    this.searchTerm = searchTerm;
    this.filterAllTabs();
    this.stopLoader();
  }

  page(pagingEvent: IPageChangeEvent) {
    const type = this.types[this.tabIndex];
    this.paging[type].fromRow = pagingEvent.fromRow;
    this.paging[type].currentPage = pagingEvent.page;
    this.paging[type].pageSize = pagingEvent.pageSize;
    this.filterAllTabs();
  }

  /**
   * Perform filter on data in all tabs.
   */
  filterAllTabs = () => {
    this.types.forEach((type) => this.filter(type))
  };

  // groupData = (data): any => {
  //   return {'all':data};
  // };

  /**
   * Load data from the api, sorting into different types.
   */
  loadData = (filter: {} = this.selectFilter) => {
    this.startLoader();
    if (this.defaultPriority) {
      filter = {
        order: 'priority ASC',
      };
    }
    if (!this.originalData) {
      this.originalData = {
        all: [],
      }
    }

    this.modelService
      .getAll(filter, this.context)
      .subscribe((models: any[]) => {
        if (this.groupData) {
          this.originalData = this.groupData(models);
        }

        Object.keys(this.originalData).forEach((key) => {
          this.originalData[key] = this.originalData[key].map((record) => {
            return this.collectionFormat(record)
          });

          if (this.defaultPriority) {
            models.forEach((d, i) => {
              if (models[i].priority !== (i + 1)) {
                this.updatePrios = true;
              }
              models[i].priority = (i + 1);
            });
          }

          if (!this.groupData) {
            if (key === 'all') {
              this.originalData[key] = models;
            } else {
              this.originalData[key] = models.filter((model: any) =>
                model.type === key);
            }
          }
          if (this.paging[key]) {
            this.paging[key].total = this.originalData[key].length;
          }
          if (this.filteredTotals) {
            Object.keys(this.filteredTotals).forEach((type) => {
              if (!this.filteredTotals[type]) {
                this.filteredTotals[type] = 0;
              }
              this.filteredTotals[type] = this.originalData[type].length;
            });
          }

          this.originalData[key] = this.originalData[key].map((record) => {
            return this.collectionFormat(record)
          });
        });
        this.filterAllTabs();
        this.stopLoader();

        if (this.updatePrios) {
          this.originalData['all'].forEach((model, i) => {
            model.priority = (i + 1);
            this.modelService['update']((model._id ? model._id : model.id), model).subscribe((record) => {}, () => {});
          })
        }
      }, this.onError);
  }
  /**
   * Start the spinning loader.
   */
  startLoader = () => {
    this._loadingService.register(this.actionName);
    console.log('register:', this.actionName);
  }

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => {
    this._loadingService.resolve(this.actionName);
    this._loadingService.resolveAll();
  }

  /**
   * Called when loadData causes an error
   * @param error
   */
  onError = (error: any) => {
    this.stopLoader();
  }

  openEdit($event, itemId) {
    if ($event && (
      ($event.target && $event.target.localName === 'mat-icon' || $event.target.localName === 'button') ||
      ($event.srcElement && $event.srcElement.localName === 'mat-icon' || $event.srcElement.localName === 'button')
    )) {
      return false;
    }
    this._router.navigate([`/groups/${this.companyId}/${this.modelName}/${itemId}/edit`]);
  }

  openDetail($event, itemId) {
    if ($event && $event.target && ($event.target.localName === 'mat-icon' || $event.target.localName === 'button')) {
      return false;
    }
    this._router.navigate([`/groups/${this.companyId}/${this.modelName}/${itemId}/details`]);
  }

  click(id: string) {
    this._router.navigate([
      `/groups/${this.companyId}/${this.modelName}/${id}/details`
    ]);
  }

  onFromFilterChanged(date: Date): void {
    this.filterDates.from = date;
    this._vault.setItem(`${environment.vaultPrefix}.paymentFromFilter`, date.toISOString());

    this.addDateToFilterQuery();
    this.loadData();
  }

  onToFilterChanged(date: Date): void {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    this.filterDates.to = date;
    this._vault.setItem(`${environment.vaultPrefix}.paymentToFilter`, date.toISOString());

    this.addDateToFilterQuery();
    this.loadData();
  }

  addDateToFilterQuery(field = 'requestedDate'): void {
    if (this.filterDates.from && this.filterDates.to) {
      this.selectFilter['where'][field] = {
        'between': [
          this.filterDates.from,
          this.filterDates.to
        ]
      };
    } else if (this.filterDates.from) {
      this.selectFilter['where'][field] = {
        'gte': this.filterDates.from
      }
    } else if (this.filterDates.to) {
      this.selectFilter['where'][field] = {
        'lte': this.filterDates.to
      }
    } else {
      delete this.selectFilter['where'][field];
    }
  }
}
