import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {environment} from '../../../environments/environment';
import {InvoiceFilter} from '../../components/shared/invoices/invoice-filter';
import {UtilityService} from '../utility.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {GlobalService} from '../global.service';

@Injectable()
export class InvoiceService extends GlobalService {

  constructor(_http: HttpClient,
              _vault: CoolLocalStorage,
              _utilityService: UtilityService,
              private _authService: AuthService) {
    super(_http, _vault, _utilityService);
    this.base = environment.pasBaseUrl;
    this.setModel('invoices');
  }

  /**
   * Add filter data to a query object for HTTP calls
   * @param {object} query
   * @param {InvoiceFilter} filterData
   * @returns {object}
   */
  static addFilterToQuery(query: any, filterData: InvoiceFilter) {
    let year: any = filterData.year;
    let from: any = filterData.from;
    let to: any = filterData.to;

    from = new Date(`${year}-${(from < 2 ? `0${from}` : from)}-01 00:00:00`);

    if (filterData.to === 12) {
      to = 1;
      year++;
    } else {
      to++;
    }

    to = new Date(`${year}-${(to < 2 ? `0${to}` : to)}-01 00:00:00`);

    // @ts-ignore
    query.where.created = {between: [from.toISOString(), to.toISOString()]};

    return query;
  }

  public getHeaders(context?: string) {
    return this._authService.getJWTHeaders();
  }

  /**
   * Get open invoices
   * @param {any} query
   * @param {any} filterData
   * @returns {Observable<any[]>}
   */
  getInvoices(query: any, filterData: any) {
    if (!query.where) {
      query.where = {};
    }
    query.include = [
      'Issuer',
      'Receiver',
      'Billable',
      {
        'relation': 'SpecificationLines',
        'scope': {
          'include': {
            'scope': {
              'relation': 'ride'
            }
          }
        }
      }
    ];

    if(filterData) {
      query = InvoiceService.addFilterToQuery(query, filterData);
    }

    // Run the call
    return this._http.get<any[]>(
      `${environment.pasBaseUrl}/Invoices/?filter=${encodeURIComponent(JSON.stringify(query))}`,
      {headers: this._authService.getJWTHeaders()}
    );
  }

  sendEmailInvoices(id: any, sendCopy: string) {
    // Run the call
    return this._http.get<any[]>(
      `${environment.pasBaseUrl}/invoices/${id}/sendInvoiceEmail?sendCopy=${sendCopy}`,
      {headers: this._authService.getJWTHeaders()});
  }

  generateDraftInvoices(type: string, jobs: any, groupProcess: string) {
    // Run the call
    return this._http.post<any[]>(
      `${environment.pasBaseUrl}/invoices/generateDraftInvoices`,
      {
        type: type,
        groupProcess: groupProcess,
        jobs: jobs
      },
      {headers: this._authService.getJWTHeaders()}
    );
  }

  generateCreditInvoices(id: any) {
    // Run the call
    return this._http.get<any[]>(
      `${environment.pasBaseUrl}/invoices/${id}/generateCreditInvoice`,
      {headers: this._authService.getJWTHeaders()}
    );
  }

  downloadInvoices(invoices, companyId) {
    return this._http.post<any[]>(
      `${environment.pasBaseUrl}/invoices/downloadInvoices`,
      {
        invoices,
        companyId
      },
      {headers: this._authService.getJWTHeaders()}
    );
  }

  restartExport(companyId) {
    return this._http.post<any[]>(
      `${environment.pasBaseUrl}/invoices/restartExport`,
      {
        companyId
      },
      {headers: this._authService.getJWTHeaders()}
    );
  }
}
