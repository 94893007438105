<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none max-w-700">
    <div class="invoice-container">
      <header>
        <div class="branding">
          <div class="logo" *ngIf="invoice.Issuer.logo">
            <img [src]="invoice.Issuer.logo" [alt]="invoice.Issuer.name">
          </div>
          <div class="title">
            <h1>{{ 'invoice' | translate | ucFirst }}</h1>
          </div>
        </div>
        <div class="details">
          <address class="issuer">
            <h4>{{ 'from' | translate | ucFirst }}:</h4>
            <p>
              <strong>{{ invoice.Issuer.name }}</strong><br>
              {{ invoice.Issuer.address }}<br>
              {{ invoice.Issuer.city }}, {{ invoice.Issuer.country }}<br>
              <span *ngIf="invoice.Issuer.phoneNumber">P: {{ invoice.Issuer.phoneNumber }}</span><br>
              <span *ngIf="invoice.Issuer.email">E: {{ invoice.Issuer.email }}</span><br>
            </p>
          </address>
          <address class="recipient">
            <h4>{{ 'to' | translate | ucFirst }}:</h4>
            <p>
              <strong>{{ invoice.Receiver.fname }} {{ invoice.Receiver.lname }}</strong><br>
              {{ invoice.Receiver.phoneNumber }}<br>
              {{ invoice.Receiver.emailAddress }}<br>
            </p>
          </address>
        </div>
      </header>

      <article>
        <table>
          <thead>
          <tr>
            <th class="left-align">{{ 'description' | translate | ucFirst }}</th>
            <th class="left-align">{{ 'vat_perc' | translate | ucFirst }}</th>
            <th class="right-align">{{ 'vat' | translate | ucFirst }}</th>
            <th class="right-align">{{ 'total' | translate | ucFirst }}</th>
            <th class="right-align"></th> <!-- Empty header for delete button -->
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let line of invoice.lines; let i = index">
            <td>{{ line.description }}</td>
            <td>{{ line.total.taxPerc }}%</td>
            <td class="amount">{{ (line.total.tax / 100) | currency:invoice.total.currency }}</td>
            <td class="amount">{{ (line.total.total / 100) | currency:invoice.total.currency }}</td>
            <td class="amount" *ngIf="line.type === 'manual'">
              <button mat-icon-button color="warn" (click)="deleteExistingInvoiceLine(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>

        <form [formGroup]="invoiceForm">
          <div formArrayName="lines" class="invoice-lines">
            <div *ngFor="let line of invoiceLines.controls; let i = index" [formGroupName]="i" class="invoice-line">
              <mat-form-field class="form-input">
                <mat-label>{{ 'description' | translate | ucFirst }}</mat-label>
                <input matInput formControlName="description">
              </mat-form-field>

              <mat-form-field class="form-input">
                <mat-label>{{ 'vat_percentage' | translate | ucFirst }}</mat-label>
                <mat-select formControlName="vatPerc" (selectionChange)="recalculateTotals()">
                  <mat-option *ngFor="let vat of vatOptions" [value]="vat">{{ vat }}%</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="form-input amount">
                <span matPrefix>{{ invoice.total.currency }}&thinsp;</span>
                <mat-label>{{ 'total' | translate | ucFirst }}</mat-label>
                <input matInput type="number" formControlName="total" (change)="recalculateTotals()"
                       (focus)="inputFocused($event)" (blur)="valOrZero($event.target)">
              </mat-form-field>

              <button mat-icon-button color="warn" (click)="deleteInvoiceLine(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div class="add-line-btn">
            <button mat-icon-button color="primary" (click)="addInvoiceLine()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </form>
      </article>

      <section class="balance">
        <div class="summary">
          <div class="meta-row"><span>{{ 'subtotal' | translate | ucFirst }}:</span> <span>{{ (invoice.total.total - invoice.total.tax) / 100 | currency:invoice.total.currency }}</span></div>
          <div class="meta-row"><span>{{ 'vat' | translate | ucFirst }}:</span> <span>{{ invoice.total.tax / 100 | currency:invoice.total.currency }}</span></div>
          <div class="meta-row total"><span>{{ 'total' | translate | ucFirst }}:</span> <span>{{ invoice.total.total / 100 | currency:invoice.total.currency }}</span></div>
        </div>
      </section>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions class="action-pane-max-width">
    <button (click)="save()" color="accent" flex mat-raised-button>{{ 'save' | translate | ucFirst }}</button>
    <button (click)="cancel()" flex mat-flat-button>{{ 'cancel' | translate | ucFirst }}</button>
  </mat-card-actions>
</mat-card>
