import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {CanDeactivateGuard} from 'app/guards/can-deactivate-guard';
import {Location, LocationType} from 'app/models/location';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/debounceTime';
import {LocationService} from '../../../../../../services/tps/location.service';
import {MapComponent} from '../../../../drivers/map/map.component';
import {LocationPolicy} from '../../../../../../models/tps/location-policy';
import {LocationPolicyService} from '../../../../../../services/tps/location-policy.service';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {CustomLazyAPIKeyLoader} from '../../../../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../../../../googleMapConfig';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {NavigationService} from '../../../../../../services/navigation.service';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';
import {environment} from '../../../../../../../environments/environment';
import {Company} from '../../../../../../models/company';
import {CompanyService} from '../../../../../../services/company.service';

enum Action {
  add,
  details,
}

@Component({
  selector: 'app-location-upsert',
  templateUrl: './location-upsert.component.html',
  styleUrls: ['./location-upsert.component.scss'],
  providers: [LocationService, LocationPolicyService,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class LocationUpsertComponent
  implements OnInit, CanDeactivateGuard {

  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChild('searchDisplayer') searchDisplayer: ElementRef;
  kmOptions = [0.1, 1, 2, 5, 10, 25, 50, 75, 100, 150, 200, 250, 300, 500];

  environment = environment;
  company: Company;
  updateType = 'serviceArea';

  ADDED_MSG = 'The new location was successfully added.';
  UPDATED_MSG = 'The location was successfully updated.';
  DELETED_MSG = 'Location has been deleted';

  serviceArea = false;
  action: string;
  translations: string[];
  type: LocationType;
  serviceAreaLimit = false;

  locationId: string;
  companyId: string;
  location: Location = new Location();
  locationPolicies: LocationPolicy[] = [];
  form: UntypedFormGroup;

  numberOfShapes = 0;
  draggable = false;
  editable = false;
  hasCustomShape = false;

  autoCompleteService: google.maps.places.AutocompleteService;
  autocompleteSessionToken: google.maps.places.AutocompleteSessionToken;
  searchService: google.maps.places.PlacesService;
  autoComplete = new UntypedFormControl();
  types = [
    'point',
    'area',
  ];
  currentPlace: any = null;
  // https://developers.google.com/places/supported_types
  keepGoogleLocationTypes = [
    'accounting',
    'airport',
    'amusement_park',
    'aquarium',
    'art_gallery',
    'atm',
    'bakery',
    'bank',
    'bar',
    'beauty_salon',
    'bicycle_store',
    'book_store',
    'bowling_alley',
    'bus_station',
    'cafe',
    'campground',
    'car_dealer',
    'car_rental',
    'car_repair',
    'car_wash',
    'casino',
    'cemetery',
    'church',
    'city_hall',
    'clothing_store',
    'convenience_store',
    'courthouse',
    'dentist',
    'department_store',
    'doctor',
    'electrician',
    'electronics_store',
    'embassy',
    'fire_station',
    'florist',
    'funeral_home',
    'furniture_store',
    'gas_station',
    'gym',
    'hair_care',
    'hardware_store',
    'hindu_temple',
    'home_goods_store',
    'hospital',
    'insurance_agency',
    'jewelry_store',
    'laundry',
    'lawyer',
    'library',
    'liquor_store',
    'local_government_office',
    'locksmith',
    'lodging',
    'meal_delivery',
    'meal_takeaway',
    'mosque',
    'movie_rental',
    'movie_theater',
    'moving_company',
    'museum',
    'night_club',
    'painter',
    'park',
    'parking',
    'pet_store',
    'pharmacy',
    'physiotherapist',
    'plumber',
    'police',
    'post_office',
    'real_estate_agency',
    'restaurant',
    'roofing_contractor',
    'rv_park',
    'school',
    'shoe_store',
    'shopping_mall',
    'spa',
    'stadium',
    'storage',
    'store',
    'subway_station',
    'supermarket',
    'synagogue',
    'taxi_stand',
    'train_station',
    'transit_station',
    'travel_agency',
    'veterinary_care',
    'zoo',
    // "administrative_area_level_1",
    // "administrative_area_level_2",
    // "administrative_area_level_3",
    // "administrative_area_level_4",
    // "administrative_area_level_5",
    // "colloquial_area",
    // "country",
    'establishment',
    // "finance",
    // "floor",
    // "food",
    // "general_contractor",
    // "geocode",
    // "health",
    // "intersection",
    // "locality",
    // "natural_feature",
    // "neighborhood",
    // "place_of_worship",
    // "political",
    // "point_of_interest",
    // "post_box",
    // "postal_code",
    // "postal_code_prefix",
    // "postal_code_suffix",
    // "postal_town",
    // "premise",
    // "room",
    'route',
    'street_address',
    'street_number',
    // "sublocality",
    // "sublocality_level_4",
    // "sublocality_level_5",
    // "sublocality_level_3",
    // "sublocality_level_2",
    // "sublocality_level_1",
    // "subpremise",
  ];
  apps = [];
  /**
   * Get icon.
   */
  getIcon = Location.getIcon;
  isPoint: any;
  isArea: any;
  private results = new BehaviorSubject([]);

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _locationService: LocationService,
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _locationPolicyService: LocationPolicyService,
    private _translateService: TranslateService,
    private _snackbar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private _ngZone: NgZone,
    private _navigationService: NavigationService,
    private _daAppInstallService: DaAppInstallService,
    private _companyService: CompanyService,
  ) {
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    this.isPoint = this.isType('point');
    this.isArea = this.isType('area');
    this.autoComplete.valueChanges
      .debounceTime(150)
      .subscribe(q => this.search(q))

    _translateService.get(['location_confirm_leave', 'unsaved_changes', 'leave', 'stay', 'location_confirm_delete_message', 'location_confirm_delete_title', 'delete', 'cancel', 'yes', 'location_added_success', 'location_updated_success', 'location_deleted_success'
    ]).subscribe((translations: any) => {
      this.translations = translations;

      this.ADDED_MSG = this.translations['location_added_success'];
      this.UPDATED_MSG = this.translations['location_updated_success'];
      this.DELETED_MSG = this.translations['location_deleted_success'];
    });
  }

  /**
   * Location descriptors by type.
   */
  descriptors = (): string[] => Location.descriptors[this.type] || [];

  /**
   * Get subtitle based on location type.
   */
  subtitle = () => {
    if (!this.hasShape()) {
      return ({
        point: 'no_shape_areas_subtitle',
        area: 'no_shape_areas_subtitle'
      }[this.type] || 'locations_subtitle');
    } else {
      return ({
        point: 'areas_subtitle',
        area: 'areas_subtitle'
      }[this.type] || 'locations_subtitle');
    }
  };

  /**
   * Query param and form type must be equal.
   */
  isNew = () => !this.locationId;
  isType = (type: 'point' | 'area') => () =>
    this.form && [this.form.value.type, this.type]
      .every(e => e === LocationType[type]);
  hasShape = () => !!this.numberOfShapes;
  isShared = () => this.location.isShared;

  /**
   * Accept emitted events.
   */
  onNrShapes = (n) => this.numberOfShapes = n;
  onMapReady = (map) => {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.searchService = new google.maps.places.PlacesService(
      this.searchDisplayer.nativeElement);
  };

  /**
   * On component initialize, make form.
   */
  ngOnInit() {
    const self = this;
    this._route.parent.params.subscribe(first => {
      this.companyId = first['id'];
      this._daAppInstallService.getAll({where: {companyId: this.companyId}}, 'company')
        .subscribe((apps) => {
          self.apps = apps.map((a) => {
            return a.daAppId;
          })
        });

      this._route.params.subscribe(second => {
        if (second) {
          if (second['id']) {
            this.locationId = second['id'];
          }
          this.action = second['action'];
          this.type = second['model'];
        }
        this._ngOnInit();
        if (Action['add'] === Action[this.action]) {
          this.initForm();
        } else {
          this.startLoader();
          this.loadData();
        }
      });
    });
  }

  _ngOnInit(): void {}

  /**
   * Guard checks whether component can be deactivated.
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.pristine) {
      return true;
    }
    return this._dialogService.openConfirm({
      message: this.translations['location_confirm_leave'],
      disableClose: false,
      title: this.translations['unsaved_changes'],
      acceptButton: ucFirst(this.translations['leave']),
      cancelButton: ucFirst(this.translations['stay']),
    }).afterClosed();
  }

  protected save() {
    if (this.form.pristine) {
      return;
    }

    this.mapComponent.markAsPristine();
    this.mapComponent.persist();
    const data = this.form.value;
    delete data.radius;
    if (Action[this.action] === Action['add']) {
      this.insert(data);
    } else {
      this.update(data);
    }
  }

  private insert(data: any) {
    this.startLoader();
    data.companyId = this.companyId;
    this._locationService.insert(data)
      .subscribe(() => {
        this._snackbar.open(this.ADDED_MSG, '', {duration: 3000});
        this._router.navigate([`/groups/${this.companyId}/locations`]);
        this.stopLoader();
      });
  }

  private update(data: any) {
    this.startLoader();
    this._locationService.update(this.locationId, data)
      .subscribe(result => {
        this.location = result;
        this._snackbar.open(this.UPDATED_MSG, '', {duration: 3000});
        this.stopLoader();
      });
  }

  protected delete() {
    this._dialogService.openConfirm({
      message: this.translations['location_confirm_delete_message'],
      title: this.translations['location_confirm_delete_title'],
      acceptButton: ucFirst(this.translations['delete']),
      cancelButton: ucFirst(this.translations['cancel']),
      disableClose: true,
    }).afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.startLoader();
          this.form.markAsPristine();
          this._locationService.delete(this.locationId).subscribe(() => {
            this._snackbar.open(this.DELETED_MSG, '', {duration: 3000});
            this._router.navigate([`/groups/${this.companyId}/locations`]);
            this.stopLoader();
          });
        }
      });
  }

  private search(input: string) {
    if (!input || input.length < 2) {
      return this.results.next([]);
    }

    const filter = (result) =>
      result.types.some(t => this.isPoint()
        ? this.keepGoogleLocationTypes.includes(t)
        : true);

    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
    this.autoCompleteService.getPlacePredictions(
      {
        input: input,
        sessionToken: this.autocompleteSessionToken
      }, results => {
        this.results.next(results);
        // this._ngZone.run(() => {
        //   const array = Array.isArray(results) ? results : [];
          // const filtered = array.filter(filter);
          // this.predefinedSearch(input)
          //   .subscribe(predefined => {
          //     if (!predefined) {
          //       return;
          //     }
          //     this.results.next([...predefined, ...filtered])
          //   });
        // });
      });

  }

  private predefinedSearch = (query) =>
    this._locationService.getAll({
      where: {
        and: [
          {
            isShared: true
          },
          {
            or: [
              {address: {regexp: query + '/i'}},
              {name: {regexp: query + '/i'}},
            ]
          }
        ]
      },
      limit: 5,
    });

  private importPoint = (place: any) => {
    const {
      formatted_address, name, geometry: {location}
    } = place;
    this.form.patchValue({
      name,
      address: formatted_address,
    });

    const point = {
      type: 'Point',
      coordinates: [location.lat, location.lng],
      radius: 200,
    };
    if (this.form.controls.radius.value) {
      point.radius = this.form.controls.radius.value * 1000 / 2;
    }

    this.mapComponent.addPoint(point);
    this.form.markAsTouched();
    this.form.markAsDirty();
  };

  protected importArea = (place: any) => {
    const {
      formatted_address, name, geometry: {location, viewport}
    } = place;
    this.form.patchValue({
      name,
      address: formatted_address,
    });
    const point = {
      type: 'Point',
      coordinates: [location.lat, location.lng],
      viewport,
      radius: 0
    };

    if (this.form.controls.radius.value) {
      point.radius = this.form.controls.radius.value * 1000 / 2;
    }

    this.mapComponent.addPoint(point);
    this.form.markAsTouched();
    this.form.markAsDirty();
  };

  private import(res) {
    this.results.next([]);

    if (res.isShared && this.updateType !== 'serviceArea') {
      return this._router.navigate([
        `/groups/${this.companyId}/locations/${this.types[res.type]}/${res._id}/import`
      ]);
    } else if (res.isShared && this.updateType === 'serviceArea') {
      const point = {
        type: 'Point',
        coordinates: [res.point.lat, res.point.lng],
        radius: 0
      };

      if (this.form.controls.radius.value) {
        point.radius = this.form.controls.radius.value * 1000 / 2;
      }

      this.mapComponent.addPoint(point);
      this.form.markAsTouched();
      this.form.markAsDirty();
      return;
    }

    const self = this;
    const commit = place => (this.isPoint() ? this.importPoint(place) : this.importArea(place));
    this.mapComponent.shapes.length
      ? this._dialogService.openConfirm({
        message: this.translations['location_confirm_clear'],
        disableClose: false,
        title: this.translations['location_confirm_clear_title'],
        cancelButton: ucFirst(this.translations['cancel']),
        acceptButton: ucFirst(this.translations['yes']),
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.mapComponent.clearGeometry()
        }
        this.searchService.getDetails({placeId: res.place_id}, commit);
      })
      : this.searchService.getDetails({placeId: res.place_id}, (place) => {
        self.currentPlace = place;
      });
  }

  private toggleEditable(checked) {
    this.editable = checked;
    this.mapComponent.setEditMode(this.editable, this.draggable);
  }

  private toggleDraggable(checked) {
    this.draggable = checked;
    this.mapComponent.setEditMode(this.editable, this.draggable);
  }

  private initForm() {
    this._locationPolicyService.getAll({where: {companyId: this.companyId}})
      .subscribe((policies: LocationPolicy[]) => {
        // console.log(`[LocationUpsertComponent.policies]:`, policies);
        this.locationPolicies = policies;
        if (this.type === 'point') {
          this.location.radius = 0.1;
        }
        this.form = this.makeFormFor(this.location);
      });
  }

  private loadData = () => {
    if (this.locationId) {
      this._locationService.get(this.locationId, {
        where: {companyId: this.companyId}
      }).subscribe((location: Location) => {
        this.location = location;
        this.convertGPSArea();
        this.initForm();
        this.stopLoader();
      });
    }
  }

  private makeFormFor = (location: Location): UntypedFormGroup => {
    const descriptor = location.descriptor;
    return this._fb.group({
      name: [location.name, [Validators.required]],
      type: [(this.type === 'point' ? 'point' : 'area')],
      address: [location.address],
      radius: [(location.radius ? location.radius : 100)],
      descriptor: [
        location.descriptor
          ? descriptor
          : this.type === 'point' ? 'address' : 'city'
      ],
      point: [location.point, [Validators.required]],
      area: [location.area],
      locationPolicyId: [location.locationPolicyId ? location.locationPolicyId : '']
    });
  };

  private convertGPSArea = () => {
    const convertedArray = [];

    if (this.location.area.type === 'MultiPolygon') {
      if (this.location.area.coordinates && this.location.area.coordinates[0]) {
        this.location.area.coordinates.forEach((c, i) => {
          convertedArray[i] = [];
          convertedArray[i][0] = [];
          this.location.area.coordinates[i][0].forEach((coordinate, x) => {
            convertedArray[i][0][x] = [];
            convertedArray[i][0][x].push(coordinate[1], coordinate[0]);
          });
        });
      } else {
        this.serviceAreaLimit = false;
      }
    } else if (this.location.area.type === 'Polygon') {
      convertedArray[0] = [];
      if (this.location.area.coordinates && this.location.area.coordinates[0]) {
        this.location.area.coordinates[0].forEach((c, i) => {
          convertedArray[0][i] = [];
          convertedArray[0][i].push(c[1], c[0]);
        });
      } else {
        this.serviceAreaLimit = false;
      }
    } else {
      this.serviceAreaLimit = false;
    }
    this.location.area.coordinates = convertedArray;
  }

  protected formDisabled = (): boolean =>
    !this.form
    || !this.form.dirty
    || !this.form.valid;

  protected startLoader = (): boolean =>
    this._loadingService.register('location');

  protected stopLoader = (): boolean =>
    this._loadingService.resolve('location')

  clearLocation() {
    this._dialogService.openConfirm({
      message: this._translateService.instant('location_confirm_clear'),
      disableClose: false,
      title: this._translateService.instant('location_confirm_clear_title'),
      cancelButton: ucFirst(this.translations['cancel']),
      acceptButton: ucFirst(this.translations['yes']),
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        this.form.controls.area.setValue({});
        this.form.controls.name.setValue('');
        this.currentPlace = null;
        this.autoComplete.setValue(null);
        this.mapComponent.clearGeometry()
        this.form.markAsDirty();
        this.numberOfShapes = 0;
      }
    });
  }
}
