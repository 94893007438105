import {Component, OnDestroy, OnInit} from '@angular/core';
import {InvoiceSettings} from '../../../../models/pas/invoice-settings';
import {SettingsService} from '../../../../services/pas/settings.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../../../services/navigation.service';
import {UpsertComponent} from '../../../global/upsert/upsert.component';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {CompanyService} from '../../../../services/company.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-invoice-settings',
  templateUrl: './invoice-settings.component.html',
  providers: [SettingsService],
  styleUrls: ['./invoice-settings.component.scss']
})
export class InvoiceSettingsComponent extends UpsertComponent implements OnInit, OnDestroy {
  companyId: string;
  settings: InvoiceSettings = new InvoiceSettings();
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  image: any;
  phoneNumber: any;
  savedMessage: string;
  modelName = 'company-settings';
  localTranslation: string[];
  processFlow = ['manual', 'auto'];
  groupFlow = ['group_on_draft', 'per_job'];

  constructor(
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _companyService: CompanyService,
    private _settingsService: SettingsService,
    private _snackbar: MatSnackBar,
    private _vault: CoolLocalStorage,
    private _titleService: Title,
    private _route: ActivatedRoute
  ) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackBar, _companyService, _translateService, _route);
    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.company = company;
    this.data = company;
    // this.id = company.id;
    // this.timezones = timezones;
    const parentParams: any = this._route.parent.snapshot.params;
    this.companyId = parentParams.id;

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    _translateService.get([
      `${this.modelName}_page_title`,
      `${this.modelName}_added_message`,
      `${this.modelName}_updated_message`,
      `ok`,
      `invoice_settings_page_title`,
    ]).subscribe((translations: any) => {
      this.localTranslation = translations;
      if (this.localTranslation[`${this.modelName}_added_message`]) {
        this.translations[`added_message`] = this.localTranslation[`${this.modelName}_added_message`];
      }
      if (this.localTranslation[`${this.modelName}_updated_message`]) {
        this.translations[`updated_message`] = this.localTranslation[`${this.modelName}_updated_message`];
      }

      if (this.localTranslation[`${this.modelName}_page_title`]) {
        this.translations[`page_title`] = this.localTranslation[`${this.modelName}_page_title`];
      }
      this._navigationService.setBodyTitle(this.localTranslation[`invoice_settings_page_title`]);
      this.savedMessage = this.localTranslation[`${this.modelName}_updated_message`];
    });
  }

  ngOnInit() {
    this.loadData();
  }

  public upload(id: string, object: any, context?: string) {
    return this._companyService.upload(id, object, context);
  }

  loadData(): void {
    this._settingsService.getCompanyDetails(this.companyId).subscribe((settings: InvoiceSettings) => {
      this.settings = settings;
    }, (error) => {
      console.error(error);
    })
  }

  onClickSave(): void {
    const self = this;
    const formData = new FormData();

    // this.settings.name = this.company.name;
    this.settings.vatNr = this.company.vatNumber;
    this.settings.address1 = this.company.address;
    this.settings.address2 = this.company.postalCode;
    this.settings.city = this.company.city;
    this.settings.country = this.company.country;
    this.settings.email = this.company.email;
    this.settings.phoneNumber = this.company.phoneNumber;

    if (!this.settings.processFlow) {
      this.settings.processFlow = 'manual';
    }

    if (self.image) {
      formData.append('file', self.image);
      formData.append('location', 'invoice');
      self.upload(self.data.id, formData, 'company').subscribe((result) => {
        self.settings.logo = `${result.imageSrc}?${self.randString()}`;

        self._settingsService.updateCompanyDetails(self.companyId, self.settings).subscribe((settings: InvoiceSettings) => {
          self._snackbar.open(self.savedMessage, self.localTranslation['ok'], {duration: 3000});
          self.settings = settings;
        }, (error) => {
          console.error(error);
        });

        self.currentAction = null
      }, () => {
        self._loadingService.resolve(self.loaderName);
        self._dialogService.openAlert({
          message: ucFirst(self.translations['created_invalid_image_extention']),
          disableClose: true,
          closeButton: self.translations['ok']
        }).afterClosed().subscribe(() => {
          self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/invoice-settings`]);
          self.currentAction = null;
        });
      });
    } else {
      self._settingsService.updateCompanyDetails(self.companyId, self.settings).subscribe((settings: InvoiceSettings) => {
        self._snackbar.open(self.savedMessage, self.localTranslation['ok'], {duration: 3000});
        self.settings = settings;
      }, (error) => {
        console.error(error);
      });
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.image = file;
    }
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  randString() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const lengthOfCode = 40;
    return this.makeRandom(lengthOfCode, possible)
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }
}
