<app-to-be-invoiced-filter (filterChanged)="invoiceFilterChanged($event)"
                           (searchChanged)="search($event)"
                           [companyId]="companyId"
                           [context]="context"
                           [type]="'toBeInvoiced'">
</app-to-be-invoiced-filter>

<mat-divider></mat-divider>
<ng-template tdLoading="{{this.actionName}}">
  <div *ngIf="originalData['all'] && originalData['all'].length > 0" class="select-all">
    <button (click)="selectAllJobs()" color="accent" mat-raised-button>
      {{(allSelected ? 'deselect_all' : 'select_all')|translate|ucFirst}} {{this.originalData['all'].length}}
      {{'invoices'|translate}}
    </button>
  </div>
  <div [@multiSelect]="multiSelectedState" class="multi-select-buttons">
    <button (click)="multiAction('invoice_process', this.multiSelect.length)" *ngIf='jobSelected' color="accent"
            mat-raised-button>
      {{ 'generate_draft_invoice' | translate | ucFirst}} ({{this.multiSelect.length}})
    </button>
  </div>
  <table class="mat-clickable" td-data-table>
    <tbody *ngFor="let item of filteredData['debtors']">
    <tr *ngIf="item.type==='header'" class="debtor-row custom-header-row" td-data-table-row>
      <td *ngFor="let c of debtorColumns" [numeric]="c.numeric" td-data-table-cell>
        <span [ngSwitch]="c.name">
          <span *ngSwitchCase="'check'">
            <mat-checkbox
              [(ngModel)]="multiSelectDebtor[item.debtorId]"
              [value]="item.id"
              (ngModelChange)="toggleDebtorAction(item.debtorId)">
            </mat-checkbox>
          </span>
          <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
        </span>
      </td>
    </tr>
    <tr *ngFor="let invoice of filteredData['grouped'][item.debtorId]" class="invoice-row" td-data-table-row>
      <td *ngFor="let c of columns" [class]="(c.name==='fareAmount' ?
        'total-column' : '')" [numeric]="c.numeric" td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchCase="'requestedDate'"><span
              *ngIf="invoice[c.name]">{{invoice[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
            <span *ngSwitchCase="'departure'"><span *ngIf="invoice[c.name]" class="wrap-line"
                                                    title="{{invoice.departure}}">{{invoice[c.name]}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'destination'"><span *ngIf="invoice[c.name]" class="wrap-line"
                                                      title="{{invoice.destination}}">{{invoice.destination}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'status'"><span
              *ngIf="invoice[c.name]">{{'ride_status_' + invoice[c.name] | translate |ucFirst}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'fareAmount'"><span
              *ngIf="invoice[c.name]">{{(invoice[c.name]) | localizedCurrency}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'driverPrice'"><span
              *ngIf="invoice[c.name]">{{(invoice[c.name]) | localizedCurrency}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'driverSettlement'"><span
              *ngIf="invoice[c.name]">{{(invoice[c.name]) | localizedCurrency}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'commissionAmount'"><span
              *ngIf="invoice[c.name]">{{(invoice[c.name]) | localizedCurrency}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
            <span *ngSwitchCase="'check'">
              <mat-checkbox
                [(ngModel)]="multiSelectBox[invoice.id]"
                [value]="invoice.id"
                (ngModelChange)="toggleMultiAction()"
              >
              </mat-checkbox>
            </span>
            <span *ngSwitchDefault><span *ngIf="invoice[c.name]">{{invoice[c.name]}}</span><span
              *ngIf="!invoice[c.name]">-</span></span>
          </span>
      </td>
    </tr>
    <tr *ngIf="item && item.total" class="debtor-total-row custom-header-row total-column" td-data-table-row>
      <td colspan="6">{{'total'|translate|ucFirst}}</td>
      <td [numeric]="true" td-data-table-cell>
        {{item.total | localizedCurrency}}
      </td>
      <td></td>
    </tr>
    </tbody>
    <tbody *ngIf="!filteredData['debtors'] || filteredData['debtors'].length === 0">
    <tr>
      <td [colSpan]="columns.length"
          class="table-placeholder">
        {{'no_jobs_to_be_invoiced' | translate}}
      </td>
    </tr>
    </tbody>
  </table>
  <td-paging-bar #pagingBar (change)="page($event)"
                 *ngIf="originalData['all'] && originalData['all'].length > 0"
                 [pageSize]="paging['all'].pageSize" [total]="originalData['all'].length">
    <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
    {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
  </td-paging-bar>
</ng-template>
