import {Injectable} from '@angular/core';
import {LazyMapsAPILoaderConfigLiteral} from '@agm/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable()
export class GoogleMapConfig implements LazyMapsAPILoaderConfigLiteral {
  public apiKey: string = environment.googleApiKey;
  public libraries: string[] = ['places', 'geometry', 'drawing', 'routes'];
  public language = 'en';

  constructor(private route: ActivatedRoute) {

    if (this.route.parent) {
      const {company} = this.route.parent.snapshot.data;
      if (company && company.googleKey && company.googleKey.active) {
        this.apiKey = company.googleKey.clientSecret;
      } else {
        this.apiKey = environment.googleApiKey;
      }

      if (company && company.language) {
        this.language = company.language;
      }
    }
  }
}
