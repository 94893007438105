import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  model: string;
  base = environment.apiBaseUrl;

  constructor(public _http: HttpClient, public _vault: CoolLocalStorage, public _utilityService: UtilityService) {

  }

  public setModel(model) {
    this.model = model;
  }

  public getHeaders(context?: string) {
    const userAccessToken: any = this._vault.getObject(`${environment.vaultPrefix}.accessToken`);
    const companyAccessToken: any = this._vault.getObject(`${environment.vaultPrefix}.companyAccessToken`);

    if (userAccessToken && context !== 'company' && context !== 'portal') {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', userAccessToken.id);
    } else if (context === 'company' && companyAccessToken) {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', companyAccessToken);
    } else {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', environment.apiAccessToken);
    }
  }

  getAll(filter?: Object, context?: string): Observable<any[]> {
    filter = JSON.stringify(this._utilityService.restrictCompanyFilter(filter, (context === 'company')));
    return this._http
      .get<any[]>(`${this.base}/${this.model}?filter=${filter}`, {headers: this.getHeaders(context)});
  }

  get(id: string, filter?: Object, context?: string): Observable<any> {
    return this._http
      .get<any>(`${this.base}/${this.model}/${id}${(filter ? `?filter=${JSON.stringify(filter)}` : '')}`,
        {headers: this.getHeaders(context)});
  }

  insert(object: any, context?: string): Observable<any> {
    return this._http.post<any>(`${this.base}/${this.model}/`, object, {headers: this.getHeaders(context)})
  }

  update(id: string, object: any, context?: string): Observable<any> {
    return this._http.patch<any>(`${this.base}/${this.model}/${id}`, object, {headers: this.getHeaders(context)});
  }

  delete(id: string, context?: string): Observable<any> {
    return this._http.delete(`${this.base}/${this.model}/${id}`, {headers: this.getHeaders(context)});
  }

  count(filter: Object): Observable<any> {
    return this._http.get(
      `${this.base}/${this.model}//count?where=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()}
    );
  }

  public upload(id: string, object: any, context?: string) {
    const uploadURL = `${this.base}/${this.model}/${id}/upload`;
    return this._http.post<any>(uploadURL, object, {headers: this.getHeaders(context)});
  }

  public uploadRackspace(id: string, object: any, query: any, context?: string) {
    const uploadURL = `${environment.apiBaseUrl}/rackspace/upload?${query}`;
    const headers = this.getHeaders(context);
    headers['Content-type'] = 'application/x-www-form-urlencoded; charset=utf-8';

    return this._http.post<any>(uploadURL, object, {headers: headers});
  }

  public deleteRackspace(id: string, object: any, query: any, context?: string) {
    const uploadURL = `${environment.apiBaseUrl}/rackspace/delete?${query}`;
    const headers = this.getHeaders(context);
    headers['Content-type'] = 'application/x-www-form-urlencoded; charset=utf-8';

    return this._http.post<any>(uploadURL, object, {headers: headers});
  }
}
